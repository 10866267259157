<template>
  <div class="container-fluid main-content">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            検索条件
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <div class="input-group">
                  <label
                    class="input-group-text middle-text">
                    祈祷分類
                  </label>
                  <select
                    class="form-select"
                    id="prayClass"
                    aria-describedby="prayClass-feedback"
                    v-model="conditions.prayClass">
                    <option value="0">予約祈祷</option>
                    <option value="1">預り祈祷</option>
                  </select>
                  <div id="prayClass-feedback" class="invalid-feedback"></div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <div class="input-group">
                  <label
                    class="input-group-text middle-text">
                    ステータス
                  </label>
                  <select
                    class="form-select"
                    id="reserveStep"
                    aria-describedby="reserveStep-feedback"
                    v-model="conditions.reserveStep">
                    <option value="0">すべて</option>
                    <option value="1">予約済み</option>
                    <option value="2">読み札出力済み</option>
                  </select>
                  <div id="reserveStep-feedback" class="invalid-feedback"></div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <div class="input-group">
                  <label
                    class="input-group-text middle-text">
                    状態
                  </label>
                  <select
                    class="form-select"
                    id="status"
                    aria-describedby="status-feedback"
                    v-model="conditions.status">
                    <option value="0">すべて</option>
                    <option value="1">キャンセル以外</option>
                    <option value="2">キャンセルのみ</option>
                  </select>
                  <div id="status-feedback" class="invalid-feedback"></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <div class="input-group">
                  <span
                    class="input-group-text middle-text">
                    祈祷日
                  </span>
                  <input
                    type="date"
                    class="form-control"
                    id="fromDate"
                    aria-describedby="date-feedback"
                    :readonly="!inputablePrayPeriod"
                    :disabled="!inputablePrayPeriod"
                    v-model="conditions.fromDate">
                  <span
                    class="input-group-text">～</span>
                  <input
                    type="date"
                    class="form-control"
                    id="toDate"
                    aria-describedby="date-feedback"
                    :readonly="!inputablePrayPeriod"
                    :disabled="!inputablePrayPeriod"
                    v-model="conditions.toDate">
                  <div id="date-feedback" class="invalid-feedback"></div>
                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <div class="input-group">
                  <label
                    class="input-group-text middle-text">
                    願意
                  </label>
                  <select
                    class="form-select"
                    id="wishId"
                    aria-describedby="wishId-feedback"
                    v-model="conditions.wishId">
                    <option value="0" selected>未選択</option>
                    <option
                      v-for="w in wishes"
                      :key="w.id"
                      :value="w.id">
                      {{ w.wishName }}
                    </option>

                  </select>
                  <div id="wishId-feedback" class="invalid-feedback"></div>
                </div>
              </div>
            </div>
            <div class="row d-none d-sm-none d-md-flex">
              <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                <div class="input-group">
                  <span
                    class="input-group-text middle-text">
                    祈願者名
                  </span>
                  <input
                    type="text"
                    class="form-control"
                    id="prayerName"
                    aria-describedby="prayerName-feedback"
                    v-model="conditions.prayerName">
                  <div id="prayerName-feedback" class="invalid-feedback"></div>
                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                <div class="input-group">
                  <span
                    class="input-group-text middle-text">
                    住所
                  </span>
                  <input
                    type="text"
                    class="form-control"
                    id="address"
                    aria-describedby="address-feedback"
                    v-model="conditions.address">
                  <div id="address-feedback" class="invalid-feedback"></div>
                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                <div class="input-group">
                  <span
                    class="input-group-text middle-text">
                    申込者
                  </span>
                  <input
                    type="text"
                    class="form-control"
                    id="applicant"
                    aria-describedby="applicant-feedback"
                    v-model="conditions.applicant">
                  <div id="applicant-feedback" class="invalid-feedback"></div>
                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                <div class="input-group">
                  <span
                    class="input-group-text middle-text">
                    連絡先
                  </span>
                  <input
                    type="text"
                    class="form-control"
                    id="contactAddress"
                    aria-describedby="contactAddress-feedback"
                    v-model="conditions.contactAddress">
                  <div id="contactAddress-feedback" class="invalid-feedback"></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="input-group">
                  <span
                    class="input-group-text middle-text">
                    キーワード
                  </span>
                  <input
                    type="text"
                    class="form-control"
                    v-model="conditions.keyword">
                </div>
              </div>
              <div class="col-auto">
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="onSearchButtonClick">
                  検索
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="row g-1 mt-1 d-none d-sm-none d-md-flex">
      <table
        class="table table-sm table-bordered table-hover table-striped connecten-table">
        <thead>
          <tr>
            <th class="button-column">
              <div
                class="form-check form-switch">

                <input
                  type="checkbox"
                  class="form-check-input"
                  v-model="isAllSelected"
                  @change="onAllSelectedChange">

              </div>
            </th>
            <th class="button-column"></th>
            <th>願意</th>
            <th>祈祷日時</th>
            <th>祈願主</th>
            <th>役職・代表者</th>
            <th>住所</th>
            <th>連絡先</th>
            <th></th>
            <th>ｽﾃｰﾀｽ</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in rows" :key="row.prayerId" :class="`${row.reserveIsDeleted ? 'table-secondary' : ''}`">
            <td class="button-column">
              <div
                class="form-check form-switch">

                <input
                  type="checkbox"
                  class="form-check-input"
                  v-model="row.isSelected">

              </div>
            </td>
            <td class="button-column">
              <a
                :href="`/reserve/edit/${row.reserveId}`"
                target="_blank"
                class="btn btn-sm btn-outline-success">
                <i class="bi bi-pencil-square"></i>
              </a>
            </td>
            <td>
              {{ row.mainWishName }}<br>
              {{ row.subWishName }}
            </td>
            <td>
              <span v-if="!row.isNotDecideDateTime">
                {{ row.startDateDisplay }}<br>
                {{ row.startTimeDisplay }}
              </span>
              <span v-else>預り祈祷</span>
              <template v-if="row.parentReserveId !== 0">
                <br>
                <span class="badge text-bg-light">
                  <a
                    :href="`/reserve/edit/${row.parentReserveId}`"
                    target="_blank">
                    追加予約
                  </a>
                </span>
              </template>
            </td>
            <td>
              {{ row.prayerNameKana }}<br>
              {{ row.prayerName }}
            </td>
            <td>
              {{ row.representativeJobTitle }}<br>
              {{ row.representativeName }}
            </td>
            <td>
              {{ row.address1 }}<br>
              {{ row.address2 }} {{ row.address3 }}
            </td>
            <td>
              {{ row.applicantName }}<br>
              {{ row.applicantEmailAddress }}
              <!-- {{ row.applicantTelNo }} -->
            </td>
            <td>
              <template v-if="row.memo !== ''">
                <i
                  class="bi bi-journal-text text-info"
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  :data-bs-title="row.memo"></i>
              </template>
            </td>
            <td>
              <span class="badge text-bg-info" v-if="row.reserveStep === 1">予約済み</span>
              <span class="badge text-bg-success" v-if="row.reserveStep === 2">読み札済み</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row d-flex d-sm-flex d-md-none">
      <div class="col-12 mt-1"
        v-for="r in rows"
        :key="r.prayerId">
        <div
          :class="`card ${r.reserveIsDeleted ? 'card-canceled' : ''}`">
          <div class="card-body">
            <div class="row g-0">
              <div class="col">
                <p style="font-size: 0.8rem;margin-bottom: 0;">
                  祈祷日：{{ r.startDateDisplay }} {{ r.startTimeDisplay }}
                </p>
                <p style="font-size: 0.8rem;margin-bottom: 0;">
                  願意：{{ r.mainWishName }} {{ r.subWishName }}
                </p>
                <p style="font-size: 0.9rem;margin-bottom: 0;">
                  {{ r.prayerName }}
                </p>
              </div>
            </div>
          </div>
          <ul class="list-group list-group-flush">
            <li :class="`list-group-item ${r.reserveIsDeleted ? 'card-canceled' : ''}`">
              <div class="row g-0">
                <div class="col">
                  <details class="card-memo" v-if="r.memo !== ''">
                    <summary>
                      <i class="bi bi-journal-text text-info"></i>メモ
                    </summary>
                    {{ r.memo }}
                  </details>
                </div>
                <div class="col-auto">
                  <a
                    :href="`/reserve/edit/${r.reserveId}`"
                    target="_blank"
                    class="card-memo">
                    <i class="bi bi-pencil-square">編集</i>
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <nav
      class="navbar fixed-bottom bg-body-tertiary bg-primary d-none d-sm-none d-md-flex"
      data-bs-theme="dark">
      <div class="container-fluid">
        <div class="col col-lg-2 col-md-3"></div>
        <div class="col-auto">
          <button
            class="btn btn-info footer-button"
            :disabled="!viewDataOperateButtons"
            @click="onDownloadReadingSlipClick">
            読み札出力
          </button>
          <a
            id="download_slip_link"
            href="#"
            style="display:none;"
            download="読み札.xlsx">
            ダウンロード
          </a>
          <button
            class="btn btn-info footer-button"
            style="margin-left: 1rem;"
            :disabled="!viewDataOperateButtons"
            @click="onDownloadListClick">
            一覧表出力
          </button>
          <a
            id="download_list_link"
            href="#"
            style="display:none;"
            download="予約一覧.xlsx">
            ダウンロード
          </a>
        </div>
        <div class="col">

        </div>
        <div class="col-auto">
          <a
            href="/reserve/edit/0"
            class="btn btn-success footer-button">
            新規登録
          </a>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import { onMounted, reactive, ref, computed, nextTick } from 'vue'
import { useStore } from 'vuex'
import { Tooltip } from 'bootstrap'
import toast from '../lib/toast'

import httpClient from '../lib/httpClient'
import loadingPanel from '../lib/loadingPanel'
import validator from '../lib/validator'

import excelDownloader from '../lib/excelDownloader'

export default {
  setup () {
    const wishes = reactive([])
    const rows = ref([])

    const isAllSelected = ref(true)

    const conditions = reactive({
      fromDate: '',
      toDate: '',
      wishId: null,
      prayerName: '',
      address: '',
      applicant: '',
      contactAddress: '',
      status: '0',
      keyword: '',
      prayClass: '0',
      reserveStep: '0'
    })

    const store = useStore()

    const viewDataOperateButtons = computed(() => {
      return rows.value.length !== 0
    })

    const inputablePrayPeriod = computed(() => {
      return conditions.prayClass === '0'
    })

    onMounted(async () => {
      loadingPanel.loadStart()
      const res = await httpClient.get(
        '/api/v1/Wishes/GetAll',
        store.getters.token)

      wishes.splice(0)
      wishes.push(...res.data.payload)
      loadingPanel.loadComplete()
    })

    async function onSearchButtonClick () {
      loadingPanel.loadStart()
      validator.clearInvalidMessage()

      const res = await httpClient.post(
        '/api/v1/Reserve/Search',
        {
          fromDate: conditions.fromDate,
          toDate: conditions.toDate,
          wishId: conditions.wishId,
          prayerName: conditions.prayerName,
          address: conditions.address,
          applicant: conditions.applicant,
          contactAddress: conditions.contactAddress,
          status: conditions.status,
          keyword: conditions.keyword,
          prayClass: conditions.prayClass,
          reserveStep: conditions.reserveStep
        },
        store.getters.token)

      console.log(res)

      if (!res.data.isValid) {
        loadingPanel.loadComplete()
        validator.setInvalidMessages(res.data.invalidList)
        toast.showDangerToast({ message: res.data.summaryMessage })
        return
      }

      // rows.value.splice(0)
      rows.value = res.data.payload.map(p => {
        p.isSelected = true
        return p
      })

      nextTick(() => {
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
        const tooltipList = [...tooltipTriggerList]
        tooltipList.map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
      })

      loadingPanel.loadComplete()
      toast.showSuccessToast({ message: `${rows.value.length}件のデータを取得しました。` })
    }

    async function onAllSelectedChange () {
      const s = isAllSelected.value
      rows.value.forEach(r => {
        r.isSelected = s
      })
    }

    async function onDownloadReadingSlipClick () {
      const ids = rows.value.filter(r => {
        return r.isSelected
      })
        .map((r) => {
          return r.reserveId
        })

      if (ids.length === 0) {
        alert('出力対象を一件以上選択してください')
        return
      }

      const r = confirm('読み札のダウンロードを行います。\nよろしいですか？')
      if (!r) { return }

      loadingPanel.loadStart()

      const res = await httpClient.requestFileByPost(
        '/api/v1/Report/ReadingSlip',
        { ids: ids },
        store.getters.token)

      if (!res.isValid) {
        loadingPanel.loadComplete()
        toast.showDangerToast({ message: res.data.summaryMessage })
        return
      }

      excelDownloader.loadExcelFromBinary(res.data, 'download_slip_link')

      toast.showSuccessToast({ message: '出力が完了しました。' })
      loadingPanel.loadComplete()
    }

    async function onDownloadListClick () {
      const ids = rows.value.filter(r => {
        return r.isSelected
      })
        .map((r) => {
          return r.reserveId
        })

      if (ids.length === 0) {
        alert('出力対象を一件以上選択してください')
        return
      }

      const r = confirm('一覧表のダウンロードを行います。\nよろしいですか？')
      if (!r) { return }

      loadingPanel.loadStart()

      const res = await httpClient.requestFileByPost(
        '/api/v1/Report/ReserveList',
        { ids: ids },
        store.getters.token)

      if (!res.isValid) {
        loadingPanel.loadComplete()
        toast.showDangerToast({ message: res.data.summaryMessage })
        return
      }

      excelDownloader.loadExcelFromBinary(res.data, 'download_list_link')

      toast.showSuccessToast({ message: '出力が完了しました。' })
      loadingPanel.loadComplete()
    }

    return {
      conditions,
      wishes,
      rows,
      isAllSelected,
      inputablePrayPeriod,
      viewDataOperateButtons,
      onSearchButtonClick,
      onAllSelectedChange,
      onDownloadReadingSlipClick,
      onDownloadListClick
    }
  }
}
</script>
<style scoped>
.container-fluid {
  padding-top: 5px;
}
.main-content {
  margin-bottom: 60px;
}
.card {
  text-align: left;
}
.connecten-table {
  text-align: left;
}

.button-column {
  width: 30px;
}

.card-canceled {
  background: var(--bs-secondary-bg);
  border-color: var(--bs-tertiary-color);
}

.card-memo {
  font-size: 0.8rem;
}
</style>
